* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.login-container {
  background-image: url('../assets/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  overflow-y: hidden;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #0b0230;
  margin: 12px 0;
  padding: 15px 15px;
  width: 100%;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.input-container input {
  background-color: #0b0230;
  border: none;
  outline: none;
  color: white;
}

.input-container input::placeholder {
  color: white;
}

.password-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-container-password {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.login-content {
  padding-top: 20px;
  height: 100%;
}

.logo {
  width: 142px;
  height: auto;
  margin-bottom: 20px;
  padding-left: 10px;
}

.user-container {
  /* margin: 10px 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
}

.login-user-container {
  border: 2px solid white;
  border-radius: 10px;
  margin-top: 20px;
}

.user-container-content {
  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.user-img-container {
  background-color: white;
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_img {
  width: 120px;
  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.password_eye {
  width: 16px;
  color: white;
}

.form-title {
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  word-wrap: break-word;
  margin-bottom: 30px;
}

.checkbox-container,
.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: white;
  font-style: italic;
  font-weight: 500;
  word-wrap: break-word;
}

.checkbox-container {
  margin: 20px 0;
}

.checkbox {
  gap: 0.5rem;
}

.login-btn {
  text-align: center;
  background-color: #0b0230 !important;
  border: 2px solid #0b0230;
  color: white;
  text-decoration: none;
  padding: 10px 10px;
  width: 100% !important;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.login-btn-link {
  border: none;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.login-btn:hover {
  background-color: #14054a;
  transform: scale(1.05);
  color: white;
}

.login-btn-link:hover {
  color: white !important;
}

.secured-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
  font-size: 15px;
  color: white;
  cursor: pointer;
}

.secured-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  gap: 0.5rem;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .login-btn {
    width: 100%;
  }
}
