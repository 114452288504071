/* Container positioning */
.dummy-positioning {
  width: 100%;
  display: flex; /* Flex added to align items */
  align-items: center;
  justify-content: center;
}

/* Variables */
:root {
  --green: #18b30a;
  --icon-base-size: 8px;
}

/* Structure */
.success-icon {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  font-size: var(--icon-base-size);
  border-radius: 50%;
  border: 2px solid #18b30a;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);
}

/* Elements */
.success-icon__tip,
.success-icon__long {
  display: block;
  position: absolute;
  height: 4px;
  background-color: #18b30a; /* lighten(#4cc93f, 20%) */
  border-radius: 10px;
}

.success-icon__tip {
  width: 2.4em;
  top: 4.3em;
  left: 1.4em;
  transform: rotate(45deg);
  animation: tipInPlace 300ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 180ms;
  visibility: hidden;
}

.success-icon__long {
  width: 4em;
  transform: rotate(-45deg);
  top: 3.7em;
  left: 2.75em;
  animation: longInPlace 140ms ease-in-out;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: calc(300ms + 140ms);
}

/* Animations */
@keyframes showSuccess {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }
  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}
