.users__tab{
    padding: 15px 50px 50px 50px;
}

.header-container{
    padding: 30px;
}

.users__tab__padding{
    margin-top: 40px;
}

.nav-link {
    color: #000000 !important;
}