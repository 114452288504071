.funding-table{
    border: 1px solid gainsboro;
    padding-block: 10px;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.funding-table ul{
    display: flex;
    gap: 2%;
}
.funding-table ul li{
    width: calc(92%/5);
}
.funding-table .tableHead{
    font-size: 20px;
    font-weight: 800;
}
.funding-table .funding_tableBody{
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.funding-table .funding_action{
    display: flex;
    gap: 1.5rem;
}
.funding-table .funding_action img{
    border: 1px solid gainsboro;
    padding: 5px 5px;
    width: 50px;
}
.view_more{
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2rem;
    position: relative;
}
.view_more h2{
    font-size: 22px;
    color: black;
    font-weight: 800;
}
.view_more .view-more-back{
    position: absolute;
    left: 5%;
    top: 5%;
    padding: 12px;
    background-color: #f1f1f1;
    border-radius: 8px;
}
.view_more div{
    padding: 32px;
    border-radius: 8px;
    margin: auto;
    background-color: #f1f1f1;
}
.view_more div ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    color: black;
}
.view_more div ul li img{
    width: 100%;
    height: 500px;
}
.view_more span{
    display: flex;
    flex-direction: row;
    gap: 4%;
    width: 100%;
    margin-top: 2rem;
}
.view_more span button{
    border: 1px solid;
    padding: 12px;
    width: 48%;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    color: white;
}
.view_more span button:nth-child(1){
    background-color: green;
}
.view_more span button:nth-child(2){
    background-color: red;
}

.pagination__container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;

}