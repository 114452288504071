/* grid c1 */
.grid-c1 {
  display: flex;
  flex-direction: column;
}
.grid-c1-content {
  background: var(
    --background-image
  ); /* Use a CSS variable to apply the background image */
  background-size: cover;
  flex: 1;
  border-radius: 10px;
  padding: 16px;
}
.grid-c1-content .lg-value {
  margin-top: 8px;
  margin-bottom: 16px;
  color: #c7c4c6;
}
.grid-c1-content .expiry-text {
  margin-bottom: 6px;
}
.grid-c1-content .card-wrapper {
  display: flex;
}
.grid-c1-content .card-pin-hidden {
  font-size: 22px;
  margin-right: 8px;
}
.grid-c1-content span {
  color: #8a8587;
}
.grid-c1 .card-logo-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grid-c1 .card-logo {
  display: flex;
}
.grid-c1 .logo-shape1,
.grid-c1 .logo-shape2 {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}
.grid-c1 .logo-shape1 {
  background-color: var(--clr-scarlet-v1);
  margin-right: -15px;
  z-index: 0;
}
.grid-c1 .logo-shape2 {
  background-color: var(--clr-yellow);
}

@media screen and (max-width: 768px) {
  .grid-c1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
