@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');

@import url(https://db.onlinewebfonts.com/c/fea31a6e7338aec52602b8c39dc16512?family=Agrandir+Thin);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #202020;
}

body::-webkit-scrollbar {
  display: none;
}

.active {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  color: #7791cd !important;
  font-weight: 600 !important;
}

.sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: auto;
}

/* OVERLAY */

.overlay {
  height: 100vh;
  width: 100vw;
  background: rgba(0 0 0 / 50%);
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
}

.overlay.overlay-active {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0s;
}

.right {
  transform: translateX(120%);
  transition: transform 300ms ease-out;
}

.mobilenav-listview {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mobilenav-listview::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */

@media (min-width: 768px) and (max-width: 1114px) {
  .left {
    transform: translateX(60%);
    transition: transform 300ms ease-out;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .left {
    transform: translateX(30%);
    transition: transform 300ms ease-out;
  }
}

.dropdown-icon {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width: 12px;
  height: 12px;
}

.dropdown-icon:hover {
  transform: translateY(-2px);
}

/* TOGGLE  */

.toggle {
  width: 50px;
  height: 24px;
  border-radius: 12px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease-in-out; /* Adding a transition for background color */
}

.toggle.active {
  background-color: #71e656;
}

.toggle-handle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease-in-out; /* Adding a transition for the handle movement */
}
.toggle.active .toggle-handle {
  transform: translateX(26px);
}
