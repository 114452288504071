.dashboard-container {
  padding: 10px;
  flex: 4;
  overflow: scroll;
  height: 100vh;
  background-color: #f4f7fe;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dashboard-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.dashboard-container {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.dashboard-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px 10px 20px;
}

.dashboard-stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add the box-shadow property here */
}

.dashboard-stat .transaction-dashboard h1 {
  color: #a3aed0;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.dashboard-stat .transaction-dashboard h2 {
  color: #1b2559;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  word-wrap: break-word;
}

.svg-chart-container .svg-chart {
  width: 3rem;
  height: 3rem;
}

.svg-container-user {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(
    180deg,
    #082e88 4%,
    #082e88 26%,
    #082e88 69%,
    #4880ff 100%,
    rgba(8, 46, 136, 0) 100%
  );
}

.svg-container-user2 {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(180deg, #2a4ea8 0%, #2962f2 53%);
}

.svg-container-user2 svg {
  color: white;
  width: 1rem;
  height: 1rem;
}

.svg-container-user svg {
  width: 1rem;
  height: 1rem;
  color: #4880ff;
}

.activity-container {
  background: linear-gradient(135deg, #2962f2 36%, #082e88 97%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add the box-shadow property here */
}

.activity-dashboard h1 {
  color: #e9edf7;
  font-weight: 500;
  line-height: 28px;
  word-wrap: break-word;
}

.activity-dashboard h2 {
  color: white;
  font-weight: 700;
  line-height: 32px;
  word-wrap: break-word;
}

/* REPORT CSS */
/* Improve the appearance */
.report-container {
  background-color: white;
  border: 1px solid #e9e9e9;
  padding: 0px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 330px;
  color: #39426f !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.report-container .report-title {
  font-size: 18px;
  text-align: center;
  line-height: 20px;
  font-weight: 500;
  padding: 10px 0;
  color: #333c6a;
  transition: color 0.3s ease; /* Add transition for color change */
}

/* Add pulsing animation for the report-value */
.report-value {
  font-size: 25px;
  font-weight: 500;
}

.report-section {
  margin: 5px 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.report-section .report-item {
  flex: 1;
}

.report-section .report-value {
  color: #7791cd;
}

.report-performance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.report-subsection .report-subtitle {
  font-weight: 400;
  font-size: 18px;
  color: #39426f;
}

.report-subsection .report-performance {
  font-size: 16px;
  text-align: center;
  margin-top: 0;
  color: #09ce9a;
  font-weight: 500;
}

/* Add transition for the download link */
.report-download-link {
  border: 1px solid #39426f;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 10px;
  color: #39426f;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.report-download-link:hover {
  background-color: #39426f;
  color: #fff;
}

/* REMITTANCE */

.remittances-container {
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.remittances-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.3s ease-in-out;
}

.remittances-container .header:hover {
  opacity: 0.8;
}

.remittances-container .header h1 {
  font-size: 20px;
  color: #1b2559;
}

.revenue-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.revenue-item {
  border: 1px solid #4a7af4;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.revenue-item:hover {
  transform: scale(1.05);
}

.revenue-section .revenue-item h2 {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
  color: #a3aed0;
}

.revenue-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #222c5e;
}

.revenue-data p {
  padding: 2px 5px;
  background-color: #a1f1db;
  font-size: 10px;
  border-radius: 20px;
  color: #0cc694;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.revenue-data p:hover {
  background-color: #0cc694;
  color: white;
}
